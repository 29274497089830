<script setup>
import ItemSelect from '@/Components/ItemSelect.vue';

defineProps({
    params: {
        type: Array,
        required: true,
    },
});

const queryParams = defineModel({
    type: Object,
    required: true,
});
</script>

<template>
    <div v-if="params.length > 0">
        <template v-for="param in params" :key="param">
            <ItemSelect
                v-if="param === 'item_number'"
                v-model="queryParams.item_number"
                label="Artikel"
            ></ItemSelect>
        </template>
    </div>
</template>

<style scoped></style>
