<script setup>
const props = defineProps({
    status: {
        type: [Object, null],
        required: true,
    },
});
const emit = defineEmits(['open']);
const open = () => {
    if (props.status.order_count > 0) {
        emit('open', props.status);
    }
};
</script>

<template>
    <td
        class="tw-min-w-36 tw-border-b tw-border-l-0 tw-border-r-2 tw-border-t-0 tw-border-solid tw-border-r-green-700 tw-border-opacity-35"
        :class="{
            'tw-text-red-500': status.something_wrong,
            'tw-bg-red-100': status.something_wrong,
            'tw-font-bold': status.something_wrong,
            'tw-cursor-pointer': status.order_count > 0,
        }"
        @click="open"
    >
        <div v-if="status.order_count > 0">
            <div
                class="tw-flex tw-w-full tw-items-center tw-justify-center tw-text-2xl tw-font-black"
            >
                <span class="tw-mr-2">{{ status.order_count }} </span>
                <span class="tw-text-sm tw-font-normal"
                    >{{ status.line_count }} <i>z</i> &nbsp;
                    {{ status.pick_count }} <i>p</i></span
                >
            </div>
            <!--            <div class="tw-flex tw-w-full tw-justify-center tw-text-lg">

            </div>-->
        </div>
    </td>
</template>

<style scoped></style>
