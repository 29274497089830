<script setup>
import { computed } from 'vue';
import TicketContentItemSelect from '@/Pages/Ticket/Partials/TicketContentItemSelect.vue';
import LocationSelect from '@/Components/LocationSelect.vue';
import BinSelect from '@/Components/BinSelect.vue';
import BatchSelect from '@/Components/BatchSelect.vue';
import TitleOutline from '@/Components/TitleOutline.vue';

const props = defineProps({
    field: {
        type: String,
        required: true,
    },
    content: {
        type: Object,
        required: true,
    },
    type: {
        type: String,
        default: 'text',
    },
    inputClass: {
        type: String,
        default: '',
    },
    edit: {
        type: Boolean,
        default: true,
    },
    locationCode: {
        type: String,
        default: null,
    },
    itemNumber: {
        type: String,
        default: null,
    },
    errorMessages: {
        type: [Object, Array, String],
        default: null,
    },
});

const value = defineModel({
    type: [String, Number, null],
    required: true,
});

const subtype = computed(() => props.content.subtype);
const show = computed(
    () =>
        subtype.value[props.field + '_label'] !== null &&
        (props.edit || value.value !== null),
);
</script>

<template>
    <v-col v-if="show">
        <TitleOutline>
            <template #title>{{ subtype[field + '_label'] }}</template>
            <slot v-if="edit">
                <v-input
                    v-if="
                        type !== 'item' &&
                        type !== 'location_code' &&
                        type !== 'bin_code' &&
                        type !== 'batch'
                    "
                    v-model="value"
                    hide-details="auto"
                    :error-messages
                >
                    <input
                        v-if="type !== 'textarea'"
                        v-model="value"
                        :type
                        class="tw-min-h-[32px] tw-w-full tw-outline-0"
                        :class="inputClass"
                    />
                    <textarea
                        v-else
                        v-model="value"
                        class="tw-min-h-[32px] tw-w-full tw-outline-0"
                        :class="inputClass"
                    ></textarea>
                </v-input>
                <TicketContentItemSelect
                    v-if="type === 'item'"
                    v-model="value"
                    :error-messages
                ></TicketContentItemSelect>
                <LocationSelect
                    v-if="type === 'location_code'"
                    v-model="value"
                    bg-color="surface"
                    density="compact"
                    variant="plain"
                    hide-details="auto"
                    single-line
                    class="!tw-mb-0"
                    :error-messages
                ></LocationSelect>
                <BinSelect
                    v-if="type === 'bin_code'"
                    v-model="value"
                    :location_code="locationCode"
                    bg-color="surface"
                    density="compact"
                    variant="plain"
                    hide-details="auto"
                    single-line
                    class="!tw-mb-0"
                    :reset-on-location-change="false"
                    :error-messages
                ></BinSelect>
                <BatchSelect
                    v-if="type === 'batch'"
                    v-model="value"
                    :item-number="itemNumber"
                    bg-color="surface"
                    density="compact"
                    variant="plain"
                    hide-details="auto"
                    single-line
                    class="!tw-mb-0"
                    :error-messages
                ></BatchSelect>
                <!--                <ItemSelect v-model='value'></ItemSelect>-->
            </slot>
            <pre v-else class="tw-font-sans tw-text-sm">{{ value }}</pre>
        </TitleOutline>
    </v-col>
</template>

<style scoped></style>
