import { reactive, watch } from 'vue';

/**
 * @template {Object} T
 * @typedef {T & { isDirty: boolean, processing: boolean, submit: function(url: string, method: 'get'|'post'|'put'), data: function(): T, defaults: function(T), reset: function}} FormData
 */

/**
 * Creates a reactive form data object with additional attributes.
 *
 * @template {Object} T
 * @param {T} data - The initial data for the form.
 * @returns {FormData<T>} The reactive form data object with the same attributes as the input data.
 */
export function useApiForm(data) {
    let defaultData = _.cloneDeep(data);
    const customKeys = [
        'isDirty',
        'processing',
        'submit',
        'data',
        'defaults',
        'reset',
    ];
    const getData = () => {
        return Object.keys(formData).reduce((acc, key) => {
            if (!customKeys.includes(key)) {
                acc[key] =
                    formData[key] instanceof Object
                        ? _.cloneDeep(formData[key])
                        : formData[key];
            }
            return acc;
        }, {});
    };

    const setDefaults = (data) => {
        Object.keys(defaultData).forEach((key) => {
            if (data[key] !== undefined) {
                defaultData[key] = _.cloneDeep(data[key]);
            }
        });
        // defaultData = _.cloneDeep(data);
    };

    const resetForm = () => {
        Object.keys(formData).forEach((key) => {
            if (!customKeys.includes(key)) {
                formData[key] = _.cloneDeep(defaultData[key]);
            }
        });
    };

    const submit = async (url, method = 'post') => {
        formData.processing = true;
        let error = null;
        let response = null;
        try {
            response = await axios.request({
                method: method,
                url: url,
                data: getData(),
            });
        } catch (e) {
            error = e;
        }

        formData.processing = false;

        if (error) {
            throw error;
        }

        setDefaults(getData());
        formData.isDirty = false;

        return response;
    };

    const formData = reactive({
        ..._.cloneDeep(data),
        isDirty: false,
        submit: submit,
        data: getData,
        defaults: setDefaults,
        reset: resetForm,
    });

    watch(
        formData,
        () => {
            formData.isDirty = !_.isEqual(getData(), defaultData);
        },
        { immediate: true, deep: true },
    );

    return formData;
}
