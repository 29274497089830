import { onMounted, onUnmounted, ref } from 'vue';
import gs1 from 'gs1';

const defaultOptions = {
    onScanned: null,
    onFailed: null,
    length: null,
    validate: true,
    debug: false,
    pauseEventListener: ref(false),
    keyEventListeners: {},
};
export default function useBarcodeScanner(options = defaultOptions) {
    const optionParams = { ...defaultOptions, ...options };
    const scannedCode = ref('');
    const pauseEventListener = optionParams.pauseEventListener;
    let stringBuffer = ref('');
    let callback = optionParams.onScanned;
    let failCallback = optionParams.onFailed;
    const keydownEventListener = (e) => {
        if (pauseEventListener.value) {
            options.debug && console.log('Event listener paused');
            return;
        }

        if (optionParams.keyEventListeners[e.code] && e.shiftKey) {
            optionParams.keyEventListeners[e.code]();
            stringBuffer.value = '';
            return;
        }

        if (optionParams.debug) console.log(e.key);

        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
            onEnterKey(e);
        } else if (e.key.length === 1 && e.ctrlKey === false) {
            stringBuffer.value += e.key;
        }
    };

    const onEnterKey = (e = null) => {
        scannedCode.value = stringBuffer.value;
        stringBuffer.value = '';

        if (
            typeof callback === 'function' &&
            (optionParams.length === null ||
                scannedCode.value.length === optionParams.length) &&
            (!optionParams.validate || gs1.validate(scannedCode.value))
        ) {
            callback(scannedCode.value, e);
        }

        if (
            typeof failCallback === 'function' &&
            scannedCode.value.length !== optionParams.length &&
            options.validate &&
            !gs1.validate(scannedCode.value)
        ) {
            failCallback(scannedCode.value, e);
        }
    };

    onMounted(() => {
        document.addEventListener('keydown', keydownEventListener);
    });
    onUnmounted(() => {
        document.removeEventListener('keydown', keydownEventListener);
    });

    const onBarcodeScanned = (cb, failCb) => {
        callback = cb;
        failCallback = failCb;
    };

    return { scannedCode, onBarcodeScanned, stringBuffer, onEnterKey };
}
