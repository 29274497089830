<script setup>
import { useAxios } from '@vueuse/integrations/useAxios';
import { computed, ref, watch } from 'vue';
import TextField from '@/Components/TextField.vue';
import colors from 'tailwindcss/colors';
import DialogModal from '@/Components/DialogModal.vue';
import { useForm } from '@inertiajs/vue3';
import SaveButton from '@/Components/SaveButton.vue';
import SelectField from '@/Components/SelectField.vue';
import CheckboxField from '@/Components/CheckboxField.vue';

const props = defineProps({
    ticket: {
        type: Object,
        required: true,
    },
    edit: {
        type: Boolean,
        default: false,
    },
});

const show = defineModel({
    type: Boolean,
    default: false,
});

const claim = ref(null);
const { data, isFinished } = useAxios(
    route('api.ticket.claim.showCreate', { ticket: props.ticket.id }),
);
const completed = ref(null);
watch(data, (value) => {
    if (value) {
        claim.value = useForm({
            amount_damage:
                value.amount_damage === null
                    ? null
                    : parseFloat(value.amount_damage),
            amount_forwarded:
                value.amount_forwarded === null
                    ? null
                    : parseFloat(value.amount_forwarded),
            amount_received:
                value.amount_received === null
                    ? null
                    : parseFloat(value.amount_received),
            external_claims_number: value.external_claims_number,
            forwarded_at: value.forwarded_at,
            received_at: value.received_at,
            submitted_at: value.submitted_at,
            submitted_to: value.submitted_to,
            damage_refused: value.damage_refused,
            status: value.status,
        });

        completed.value = value.status === 'closed';
    }
});
const loading = ref(false);
const errors = ref({});
const submitUpdateClaimForm = async () => {
    loading.value = true;
    try {
        const response = await axios.put(
            route('api.ticket.claim.update', { ticket: props.ticket.id }),
            claim.value.data(),
        );

        const data = { ...response.data.data };
        data.amount_damage = parseFloat(data.amount_damage);
        data.amount_forwarded = parseFloat(data.amount_forwarded);
        data.amount_received = parseFloat(data.amount_received);

        claim.value.defaults(data);
        claim.value.reset();
        claim.value.isDirty = false;
        errors.value = {};
    } catch (e) {
        if (e.response.status === 422) {
            errors.value = e.response.data.errors;
        } else {
            console.error(e);
        }
    }
    loading.value = false;
};
const reset = () => {
    claim.value.reset();
    errors.value = {};
};
const close = () => {
    errors.value = {};
    show.value = false;
};

const variant = computed(() => (canEdit.value ? 'filled' : 'plain'));
const readonly = computed(() => !props.edit || completed.value);
const bgColor = computed(() => (canEdit.value ? colors.gray[100] : 'surface'));

watch(completed, (value) => {
    if (claim.value) {
        claim.value.status = value ? 'closed' : 'open';
    }
});
watch(
    () => claim.value?.status,
    (value) => {
        completed.value = value === 'closed';
    },
);

const canEdit = computed(() => {
    return props.edit && claim.value?.status === 'open';
});
</script>

<template>
    <DialogModal :show="show" @close="close">
        <template #title>
            <span class="tw-font-light tw-uppercase">Schadenseinreichung</span>
        </template>
        <template #content>
            <div
                v-if="!isFinished"
                class="tw-flex tw-h-64 tw-items-center tw-justify-center"
            >
                <v-progress-circular
                    indeterminate
                    size="large"
                    color="primary"
                ></v-progress-circular>
            </div>

            <div v-else class="tw-min-h-96">
                <v-table
                    v-if="claim"
                    :class="{
                        'tw-max-w-[800px]': canEdit,
                        'tw-max-w-[400px]': !canEdit,
                    }"
                >
                    <tr>
                        <td class="tw-pr-1 tw-align-top">
                            <TextField
                                v-model="claim.submitted_at"
                                :variant
                                :readonly
                                :bg-color
                                :class="{ 'v-field--focused': !canEdit }"
                                type="date"
                                hide-details="auto"
                                :error-messages="errors.submitted_at"
                            >
                                <template #label>
                                    <span
                                        :class="{ 'tw-text-primary': !canEdit }"
                                        >Wann</span
                                    >
                                </template>
                            </TextField>
                        </td>
                        <td class="tw-align-top">
                            <SelectField
                                v-model="claim.submitted_to"
                                :variant
                                :readonly
                                :bg-color
                                :class="{ 'v-field--focused': !canEdit }"
                                :items="[
                                    'Ö-Post',
                                    'DHL',
                                    'Allianz (LVS)',
                                    'Maillog',
                                ]"
                                hide-details="auto"
                                :clearable="!readonly"
                                :error-messages="errors.submitted_to"
                            >
                                <template #label>
                                    <span
                                        :class="{ 'tw-text-primary': !canEdit }"
                                        >Bei wem</span
                                    >
                                </template>
                            </SelectField>
                        </td>
                    </tr>
                    <tr>
                        <td class="tw-pr-1 tw-align-top">
                            <TextField
                                v-model="claim.external_claims_number"
                                :variant
                                :readonly
                                :bg-color
                                :class="{ 'v-field--focused': !canEdit }"
                                hide-details="auto"
                                :error-messages="errors.external_claims_number"
                            >
                                <template #label>
                                    <span
                                        :class="{ 'tw-text-primary': !canEdit }"
                                        >Ext. Schadensnummer</span
                                    >
                                </template>
                            </TextField>
                        </td>
                        <td class="tw-align-top">
                            <TextField
                                v-model="claim.amount_damage"
                                :variant
                                :readonly
                                :bg-color
                                :class="{ 'v-field--focused': !canEdit }"
                                hide-details="auto"
                                type="number"
                                step="0.01"
                                :error-messages="errors.amount_damage"
                            >
                                <template #label>
                                    <span
                                        :class="{ 'tw-text-primary': !canEdit }"
                                        >Wert S.Rechnung</span
                                    >
                                </template>
                            </TextField>
                        </td>
                    </tr>
                    <tr>
                        <td class="tw-pr-1 tw-align-top">
                            <TextField
                                v-model="claim.amount_received"
                                :variant
                                :readonly
                                :bg-color
                                :class="{ 'v-field--focused': !canEdit }"
                                hide-details="auto"
                                type="number"
                                step="0.01"
                                :error-messages="errors.amount_received"
                            >
                                <template #label>
                                    <span
                                        :class="{ 'tw-text-primary': !canEdit }"
                                        >Wert erhalten</span
                                    >
                                </template>
                            </TextField>
                        </td>
                        <td class="tw-align-top">
                            <TextField
                                v-model="claim.received_at"
                                :variant
                                :readonly
                                :bg-color
                                :class="{ 'v-field--focused': !canEdit }"
                                hide-details="auto"
                                type="date"
                                :error-messages="errors.received_at"
                            >
                                <template #label>
                                    <span
                                        :class="{ 'tw-text-primary': !canEdit }"
                                        >Wann Wert erhalten</span
                                    >
                                </template>
                            </TextField>
                        </td>
                    </tr>
                    <tr>
                        <td class="tw-pr-1 tw-align-top">
                            <TextField
                                v-model="claim.amount_forwarded"
                                :variant
                                :readonly
                                :bg-color
                                :class="{ 'v-field--focused': !canEdit }"
                                hide-details="auto"
                                type="number"
                                step="0.01"
                                :error-messages="errors.amount_forwarded"
                            >
                                <template #label>
                                    <span
                                        :class="{ 'tw-text-primary': !canEdit }"
                                        >Wert weitergegeben</span
                                    >
                                </template>
                            </TextField>
                        </td>
                        <td class="tw-align-top">
                            <TextField
                                v-model="claim.forwarded_at"
                                :variant
                                :readonly
                                :bg-color
                                :class="{ 'v-field--focused': !canEdit }"
                                hide-details="auto"
                                type="date"
                                :error-messages="errors.forwarded_at"
                            >
                                <template #label>
                                    <span
                                        :class="{ 'tw-text-primary': !canEdit }"
                                        >Wert weitergegeben wann</span
                                    >
                                </template>
                            </TextField>
                        </td>
                    </tr>
                    <tr>
                        <td class="tw-pr-1 tw-align-top">
                            <TextField
                                v-model="claim.damage_refused"
                                :variant
                                :readonly
                                :bg-color
                                :class="{ 'v-field--focused': !canEdit }"
                                type="date"
                                hide-details="auto"
                                :error-messages="errors.damage_refused"
                            >
                                <template #label>
                                    <span
                                        :class="{ 'tw-text-primary': !canEdit }"
                                        >Abgelehnt</span
                                    >
                                </template>
                            </TextField>
                        </td>
                    </tr>
                </v-table>
                <div v-if="claim" class="tw-w-full">
                    <CheckboxField
                        v-model="completed"
                        :readonly="!props.edit"
                        label="Abgeschlossen"
                        :hide-details="false"
                        :error-messages="errors.status"
                    ></CheckboxField>
                </div>
            </div>
        </template>
        <template v-if="isFinished" #footer>
            <v-spacer></v-spacer>
            <v-btn
                :disabled="!claim.isDirty || loading"
                variant="elevated"
                color="danger"
                @click="reset"
                >{{ $t('Reset') }}</v-btn
            >
            <SaveButton
                type="button"
                :loading="loading"
                :disabled="!claim.isDirty"
                @click="submitUpdateClaimForm"
            ></SaveButton>
        </template>
    </DialogModal>
</template>

<style scoped></style>
