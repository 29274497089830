<script setup>
const props = defineProps({
    params: {
        type: Object,
        required: true,
    },
});

const click = (event) => {
    event.preventDefault();
    props.params.click(props.params.value);
};
</script>

<template>
    <a v-if="params.value" href="#" @click="click">{{ params.value }}</a>
</template>

<style scoped></style>
