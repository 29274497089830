<script setup>
import { mdiArrowRightBottom, mdiNoteTextOutline } from '@mdi/js';
import LinkVuetify from '@/Components/LinkVuetify.vue';

defineProps({
    pickList: {
        type: Object,
        required: true,
    },
});

const formatDate = (date) => {
    return moment(date).format('DD.MM.YY HH:mm');
};
</script>

<template>
    <div class="tw-border tw-border-y-2 tw-border-solid tw-px-2 tw-py-1">
        <div class="tw-flex tw-w-full tw-justify-between tw-text-xl">
            <div
                class="tw-mr-2 tw-flex tw-min-w-0 tw-shrink tw-overflow-hidden tw-font-bold"
            >
                <div class="tw-min-w-0 tw-truncate">
                    {{ pickList.customer_name }}
                </div>
                <div class="tw-font-normal">
                    ({{ pickList.customer_number }})
                </div>
            </div>
            <div
                class="tw-grow tw-whitespace-nowrap tw-text-right tw-accent-gray-600"
            >
                {{ formatDate(pickList.created_at) }}
            </div>
        </div>
        <div
            v-if="pickList.meta?.note"
            class="tw-flex tw-w-full tw-justify-between"
        >
            <div class="tw-font-bold">
                <v-icon
                    class="tw-mr-2 tw-font-normal"
                    :icon="mdiNoteTextOutline"
                ></v-icon
                >{{ pickList.meta?.note }}
            </div>
        </div>
        <div class="tw-flex tw-w-full tw-justify-between">
            <div>{{ pickList.ean }}</div>

            <div class="tw-accent-gray-600">
                {{ pickList.servicelevels.join(', ') }}
            </div>
        </div>
        <div
            class="tw-flex tw-w-full tw-items-center tw-justify-between tw-py-1 tw-text-3xl"
        >
            <div class="tw-mr-2 tw-flex">
                <div
                    class="tw-mr-2 tw-rounded tw-border tw-border-solid tw-p-1 tw-text-center"
                >
                    <span class="tw-text-2xl">A:</span>
                    <span class="tw-font-bold">{{
                        pickList.delivery_note_count
                    }}</span>
                </div>
                <div
                    class="tw-mr-2 tw-rounded tw-border tw-border-solid tw-p-1 tw-text-center"
                >
                    <span class="tw-text-2xl">L:</span>
                    <span class="tw-font-bold">{{ pickList.bin_count }}</span>
                </div>
                <div
                    class="tw-rounded tw-border tw-border-solid tw-p-1 tw-text-center"
                >
                    <span class="tw-text-2xl">P:</span>
                    <span class="tw-font-bold">{{ pickList.pick_count }}</span>
                </div>
            </div>
            <div class="tw-flex tw-flex-col">
                <LinkVuetify
                    v-if="pickList.completed_at === null"
                    class="tw-w-auto"
                    :href="route('pick.show', pickList.ean)"
                    as="v-btn"
                    :color="pickList.started_at === null ? 'success' : 'info'"
                    :append-icon="mdiArrowRightBottom"
                    >{{ pickList.started_at === null ? 'Start' : 'Weiter' }}
                </LinkVuetify>
                <LinkVuetify
                    v-else
                    class="tw-w-auto"
                    :href="route('pick.show', pickList.ean)"
                    as="v-btn"
                    color="secondary"
                    :append-icon="mdiArrowRightBottom"
                    >Abgeschlossen</LinkVuetify
                >
                <small
                    v-if="pickList.started_at !== null"
                    class="tw-mt-1 tw-text-xs"
                    >gestarted {{ formatDate(pickList.started_at) }}</small
                >
            </div>
        </div>
    </div>
</template>

<style scoped></style>
