<script setup>
import DialogModal from '@/Components/DialogModal.vue';
import { mdiClose, mdiNoteEditOutline } from '@mdi/js';
import { useVModel } from '@vueuse/core';
import { computed, nextTick, ref, watch } from 'vue';
import TextField from '@/Components/TextField.vue';

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    item: {
        type: Object,
        default: null,
    },
});
const emit = defineEmits(['update:modelValue', 'close']);

const show = useVModel(props, 'modelValue', emit);
const editMinStock = ref(false);
const minStockInput = ref(null);
const editMinStockToggle = () => {
    editMinStock.value = !editMinStock.value;
    if (editMinStock.value) {
        nextTick(() => minStockInput.value.focus());
    }
};

const saving = ref(false);
const saveMinStock = async () => {
    if (minStockDirty.value) {
        saving.value = true;
        await axios.put(
            route('api.maillog.item.update', { item: props.item.MaillogSKU }),
            {
                minimum_stock: minStock.value,
            },
        );
        saving.value = false;
        initialMinStock.value = minStock.value;
    }
};
const onClose = async () => {
    await saveMinStock();
    await saveCostPrice();

    show.value = false;
    emit('close', {
        number: props.item.MaillogSKU,
        minimum_stock: minStock.value,
        cost_price: costPrice.value,
    });
};

const initialCostPrice = ref(null);
const initialMinStock = ref(null);
const minStock = ref(null);
watch(show, (value) => {
    if (value) {
        initialMinStock.value = props.item?.MinInventory;
        minStock.value = props.item?.MinInventory;

        initialCostPrice.value = props.item?.Direkte_Kosten;
        costPrice.value = props.item?.Direkte_Kosten;
    }
});

const minStockDirty = computed(() => {
    return initialMinStock.value !== minStock.value;
});

const onMinStockBlur = function () {
    saveMinStock();
};

const editCostPrice = ref(false);
const costPriceInput = ref(null);
const editCostPriceToggle = () => {
    editCostPrice.value = !editCostPrice.value;
    if (editCostPrice.value) {
        nextTick(() => costPriceInput.value.focus());
    }
};

const savingCostPrice = ref(false);
const costPrice = ref(null);
const costPriceDirty = computed(() => {
    return initialCostPrice.value !== costPrice.value;
});
const saveCostPrice = async () => {
    if (costPriceDirty.value) {
        savingCostPrice.value = true;
        await axios.put(
            route('api.maillog.item.update', { item: props.item.MaillogSKU }),
            {
                cost_price: costPrice.value,
            },
        );
        savingCostPrice.value = false;
        initialCostPrice.value = costPrice.value;
    }
};

const onCostPriceBlur = function () {
    saveCostPrice();
};
</script>

<template>
    <DialogModal v-model="show" class="tw-max-w-6xl" @close="onClose">
        <template #title>
            <div class="tw-mt-2 tw-flex tw-justify-between">
                <h6 class="tw-font-sans tw-text-lg">
                    {{ $t('Details') }}
                </h6>
                <v-icon :icon="mdiClose" @click="onClose"></v-icon>
            </div>
        </template>
        <template v-if="item !== null" #content>
            <div
                class="tw-rounded-md tw-border tw-border-solid tw-border-gray-200 tw-py-2 tw-shadow-md tw-shadow-gray-200"
            >
                <table class="tw-w-full tw-border-collapse">
                    <tbody>
                        <tr class="tw-align-top even:tw-bg-gray-50">
                            <th class="tw-px-4 tw-py-2 tw-text-left">
                                {{ $t('Maillog SKU') }}
                            </th>
                            <td class="tw-py-2">{{ item.MaillogSKU }}</td>
                        </tr>
                        <tr class="tw-align-top even:tw-bg-gray-50">
                            <th class="tw-px-4 tw-py-2 tw-text-left">
                                {{ $t('Customer SKU') }}
                            </th>
                            <td class="tw-py-2">{{ item.YourSKU }}</td>
                        </tr>
                        <tr class="tw-align-top even:tw-bg-gray-50">
                            <th class="tw-px-4 tw-py-2 tw-text-left">
                                {{ $t('Description') }}
                            </th>
                            <td class="tw-py-2">{{ item.Description }}</td>
                        </tr>
                        <tr class="tw-align-top even:tw-bg-gray-50">
                            <th class="tw-px-4 tw-py-2 tw-text-left">
                                {{ $t('Batch') }}
                            </th>
                            <td class="tw-py-2">{{ item.LotNo }}</td>
                        </tr>
                        <tr class="tw-align-top even:tw-bg-gray-50">
                            <th
                                class="tw-px-4 tw-py-2 tw-text-left tw-align-middle"
                            >
                                {{ $t('Minimum Stock') }}
                            </th>
                            <td class="tw-flex tw-items-center tw-py-2">
                                <TextField
                                    ref="minStockInput"
                                    v-model="minStock"
                                    :loading="saving"
                                    class="!tw-mb-0 tw-mr-2"
                                    :disabled="!editMinStock"
                                    color="primary"
                                    type="number"
                                    density="compact"
                                    hide-details
                                    variant="outlined"
                                    :label="$t('Minimum Stock')"
                                    @blur="onMinStockBlur"
                                >
                                </TextField>
                                <v-btn
                                    size="default"
                                    density="comfortable"
                                    :disabled="false"
                                    class="tw-mr-3"
                                    :icon="mdiNoteEditOutline"
                                    color="info"
                                    variant="elevated"
                                    @click="editMinStockToggle"
                                ></v-btn>
                            </td>
                        </tr>
                        <tr class="tw-align-top even:tw-bg-gray-50">
                            <th
                                class="tw-px-4 tw-py-2 tw-text-left tw-align-middle"
                            >
                                {{ $t('Cost Price') }}
                            </th>
                            <td class="tw-flex tw-items-center tw-py-2">
                                <TextField
                                    ref="costPriceInput"
                                    v-model="costPrice"
                                    :loading="savingCostPrice"
                                    class="!tw-mb-0 tw-mr-2"
                                    :disabled="!editCostPrice"
                                    color="primary"
                                    type="number"
                                    density="compact"
                                    hide-details
                                    variant="outlined"
                                    :label="$t('Cost Price')"
                                    @blur="onCostPriceBlur"
                                >
                                </TextField>
                                <v-btn
                                    size="default"
                                    density="comfortable"
                                    :disabled="false"
                                    class="tw-mr-3"
                                    :icon="mdiNoteEditOutline"
                                    color="info"
                                    variant="elevated"
                                    @click="editCostPriceToggle"
                                ></v-btn>
                            </td>
                        </tr>
                        <tr class="tw-align-top even:tw-bg-gray-50">
                            <th class="tw-px-4 tw-py-2 tw-text-left">
                                {{ $t('Expiration Date') }}
                            </th>
                            <td class="tw-py-2">{{ item.ExpDate }}</td>
                        </tr>
                        <tr class="tw-align-top even:tw-bg-gray-50">
                            <th class="tw-px-4 tw-py-2 tw-text-left">
                                {{ $t('Res. Quantity') }}
                            </th>
                            <td class="tw-py-2">
                                {{ item.reserved_quantity_total }}
                            </td>
                        </tr>
                        <tr class="tw-align-top even:tw-bg-gray-50">
                            <th class="tw-px-4 tw-py-2 tw-text-left">
                                {{ $t('Stock') }}
                            </th>
                            <td class="tw-py-2">
                                {{
                                    item.total_quantity -
                                    item.reserved_quantity_total
                                }}
                            </td>
                        </tr>
                        <tr class="tw-align-top even:tw-bg-gray-50">
                            <th class="tw-px-4 tw-py-2 tw-text-left">
                                {{ $t('Unit') }}
                            </th>
                            <td class="tw-py-2">{{ item.UnitOfMeasure }}</td>
                        </tr>
                        <tr class="tw-align-top even:tw-bg-gray-50">
                            <th class="tw-px-4 tw-py-2 tw-text-left">
                                {{ $t('Storage Location') }}
                            </th>
                            <td class="tw-py-2">{{ item.Location }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
        <template v-else #content>
            <div class="tw-w-full tw-py-24 tw-text-center">
                No Item Found...
            </div>
        </template>
    </DialogModal>
</template>

<style scoped></style>
