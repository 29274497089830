<script setup>
import { AgGridVue } from 'ag-grid-vue3';
import { ref, shallowRef, watch } from 'vue';
import { useAxios } from '@vueuse/integrations/useAxios';
import { AG_GRID_LOCALE_DE } from '@ag-grid-community/locale';
import LayoutSelect from '@/Pages/Reporting/Partials/LayoutSelect.vue';
import DateFilter from '@/Pages/Reporting/Partials/DateFilter.vue';
import EanCellRenderer from '@/Pages/Reporting/Partials/EanCellRenderer.vue';

const rowData = ref(null);
const autoGroupColumnDef = {
    filter: 'agGroupColumnFilter',
    floatingFilter: true,
    comparator: (valueA, valueB) => {
        if (valueA === null || valueA === undefined) {
            return;
        }
        if (valueB === null || valueB === undefined) {
            return;
        }
        return valueA.localeCompare(valueB);
    },
    cellClass: 'group-column',
};
const columnDefs = [
    {
        field: 'Tag',
        rowGroup: true,
        enableRowGroup: true,
        hide: true,
        valueFormatter: (params) => {
            const day = moment(params.value, 'YYYY-MM-DD');

            if (!day.isValid()) {
                return null;
            }

            return day.format('DD.MM.YYYY');
        },
        filter: 'agDateColumnFilter',
        filterParams: {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
                const filterDate = moment(filterLocalDateAtMidnight);
                const cellDate = moment(cellValue, 'YYYY-MM-DD');

                if (filterDate.isSame(cellDate, 'day')) {
                    return 0;
                }

                if (cellDate.isBefore(filterDate)) {
                    return -1;
                }

                if (cellDate.isAfter(filterDate)) {
                    return 1;
                }
            },
        },
    },
    {
        headerName: 'Name',
        valueGetter: (params) => {
            if (params.data === undefined) {
                return null;
            }

            return `${params.data.vorname} ${params.data.nachname}`;
        },
        rowGroup: true,
        enableRowGroup: true,
        hide: true,
        filter: 'agSetColumnFilter',
    },
    {
        field: 'Kundenname',
        rowGroup: true,
        enableRowGroup: true,
        hide: true,
        filter: 'agSetColumnFilter',
    },
    {
        field: 'activity',
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        floatingFilter: true,
    },
    {
        field: 'EAN-Code',
        filter: 'agTextColumnFilter',
        enableRowGroup: true,
        floatingFilter: true,
        cellRenderer: EanCellRenderer,
        cellRendererParams: {
            click: (ean) => {
                if (api.value) {
                    api.value.setColumnFilterModel('EAN-Code', {
                        filter: ean,
                        type: 'contains',
                        filterType: 'text',
                    });
                    api.value.onFilterChanged();
                }
            },
        },
    },
    {
        field: 'start',
        cellRenderer: (params) => {
            if (params.value) {
                if (tagGroup.value) {
                    return `<strong>${moment(params.value).format('HH:mm')}</strong>`;
                }

                return `<strong>${moment(params.value).format('DD.MM.YYYY')}</strong> ${moment(
                    params.value,
                ).format('HH:mm')}`;
            }
            return null;
        },
    },
    {
        field: 'stop',
        cellRenderer: (params) => {
            if (params.value) {
                if (tagGroup.value) {
                    return `<strong>${moment(params.value).format('HH:mm')}</strong>`;
                }

                return `<strong>${moment(params.value).format('DD.MM.YYYY')}</strong> ${moment(
                    params.value,
                ).format('HH:mm')}`;
            }
            return null;
        },
    },
    {
        headerName: 'Dauer',
        valueGetter: (params) => {
            if (!params.data.start || !params.data.stop) {
                return null;
            }

            const start = moment(params.data.start, 'YYYY-MM-DD HH:mm:ss');
            const stop = moment(params.data.stop, 'YYYY-MM-DD HH:mm:ss');
            const duration = moment.duration(stop.diff(start));

            return Math.ceil(duration.asMinutes());
        },
        cellRenderer: (params) => {
            const duration = moment.duration(params.value, 'minutes');

            if (duration.asHours() < 1) {
                return `<b>${duration.asMinutes().toString().padStart(2, '0')}</b>min`;
            }

            return (
                `<b>${Math.floor(duration.asHours()).toString().padStart(2, '0')}</b>h ` +
                `<b>${(duration.asMinutes() % 60).toString().padStart(2, '0')}</b>min`
            );
        },
        type: 'numericColumn',
        aggFunc: 'sum',
        enableValue: true,
    },
    {
        field: 'Detail',
    },
];

const { data, isFinished } = useAxios(route('api.reporting.index'));

watch(isFinished, () => {
    if (isFinished.value) {
        rowData.value = data.value;
    }
});

const api = shallowRef(null);
const sideBar = ref('columns');

const onGridReady = (params) => {
    onColumnRowGroupChanged(params);
    api.value = params.api;
};

const customerGroup = ref(false);
const tagGroup = ref(false);
const eanGroup = ref(false);
const onColumnRowGroupChanged = (params) => {
    const columns = params.api.getRowGroupColumns();

    const column = columns.find((column) => column.colId === 'Kundenname');
    customerGroup.value = !!column;

    const columnTag = columns.find((column) => column.colId === 'Tag');
    tagGroup.value = !!columnTag;

    const columnEan = columns.find((column) => column.colId === 'EAN-Code');
    eanGroup.value = !!columnEan;
};

watch([customerGroup, eanGroup], () => {
    api.value.onFilterChanged();
});

const isExternalFilterPresent = () => {
    return true;
};

const doesExternalFilterPass = (node) => {
    let datePasses = true;
    if (filter.value.type !== undefined) {
        switch (filter.value.type) {
            case 'this_month':
                datePasses = moment(node.data.Tag, 'YYYY-MM-DD').isSame(
                    moment(),
                    'month',
                );
                break;
            case 'last_month':
                datePasses = moment(node.data.Tag, 'YYYY-MM-DD').isSame(
                    moment().subtract(1, 'month'),
                    'month',
                );
                break;
            case 'date_range':
                datePasses = moment(node.data.Tag, 'YYYY-MM-DD').isBetween(
                    moment(filter.value.startDate, 'MM/YY').startOf('month'),
                    moment(filter.value.endDate, 'MM/YY').endOf('month'),
                    'day',
                    '()',
                );
                break;
        }
    }

    const customerPasses =
        !customerGroup.value || node.data.Kundenname !== null;
    const eanPasses = !eanGroup.value || node.data['EAN-Code'] !== null;

    return customerPasses && eanPasses && datePasses;
};

const filter = ref({
    type: 'this_month',
    startDate: null,
    endDate: null,
});

const applyFilters = () => {
    if (!api.value) {
        return;
    }
    api.value.onFilterChanged();
};
</script>

<template>
    <v-card class="tw-flex tw-h-full tw-w-full tw-flex-col">
        <div id="layout-select" class="tw-flex tw-shrink tw-p-2">
            <DateFilter v-model="filter" @apply="applyFilters"></DateFilter>
            <v-spacer></v-spacer>
            <LayoutSelect
                v-model:filter="filter"
                :grid-api="api"
            ></LayoutSelect>
        </div>
        <ag-grid-vue
            class="ag-theme-material ag-theme-material-dense tw-grow"
            :locale-text="AG_GRID_LOCALE_DE"
            :side-bar="sideBar"
            :row-data
            :auto-group-column-def="autoGroupColumnDef"
            :column-defs
            group-display-type="singleColumn"
            :group-allow-unbalanced="true"
            row-group-panel-show="always"
            :is-external-filter-present="isExternalFilterPresent"
            :does-external-filter-pass="doesExternalFilterPass"
            @grid-ready="onGridReady"
            @column-row-group-changed="onColumnRowGroupChanged"
        >
        </ag-grid-vue>
    </v-card>
</template>

<style scoped>
#layout-select {
    background-color: #eee;
}

:deep(.ag-row-level-1) {
    background-color: theme('colors.gray.50') !important;
}

:deep(.ag-row-level-2) {
    background-color: theme('colors.gray.100') !important;
}

:deep(.ag-row-level-3) {
    background-color: theme('colors.gray.200') !important;
}

:deep(.group-column) {
    background-color: #f4d9d5;
}
:deep(.ag-row-hover > .group-column) {
    background-color: #f8d0cb !important;
}

:deep(.ag-row-level-1 > .group-column) {
    background-color: #f1b9b2;
}

:deep(.ag-row-level-2 > .group-column) {
    background-color: #ecaaa1;
}

:deep(.ag-row-level-3 > .group-column) {
    background-color: #f4d9d5;
}
</style>
