<script setup>
import { useForm, usePage } from '@inertiajs/vue3';
import FormSection from '@/Components/FormSection.vue';
import TextareaField from '@/Pages/Order/TextareaField.vue';
import SelectField from '@/Components/SelectField.vue';
import SaveButton from '@/Components/SaveButton.vue';
import DialogModal from '@/Components/DialogModal.vue';
import { mdiAlert } from '@mdi/js';
import { onMounted, ref } from 'vue';

const page = usePage();

const priorities = [
    { text: 'Standard', value: 0 },
    { text: 'Hoch', value: 1 },
];

const form = useForm({
    note: page.props.meta?.note ?? '',
    priority: page.props.meta?.priority ?? 0,
});
const submit = () => {
    form.post(route('pick.note.store', { pick_ean: page.props.ean }));
};

const showWarning = ref(false);
onMounted(() => {
    showWarning.value = page.props.started;
});
</script>

<template>
    <v-container>
        <DialogModal
            card-color="danger"
            :show="showWarning"
            @close="showWarning = false"
        >
            <template #title
                ><div class="tw-flex tw-items-center">
                    <v-icon :icon="mdiAlert" class="tw-mr-2"></v-icon>
                    Achtung
                </div></template
            >
            <template #content
                >Pickliste
                <span class="tw-font-bold">{{ page.props.ean }}</span> wurde
                bereits gestartet.</template
            >
        </DialogModal>
        <FormSection @submitted="submit">
            <template #title
                >Pickliste
                <span class="tw-font-bold">{{ page.props.ean }}</span></template
            >
            <template #description>
                Fügen Sie der Pickliste {{ page.props.ean }} eine Notiz hinzu.
                Diese wird im Pick Prozess für den Lagermitarbeiter nangezeigt.
            </template>
            <template #form>
                <TextareaField v-model="form.note" label="Notiz" />
                <SelectField
                    v-model="form.priority"
                    :items="priorities"
                    item-title="text"
                    item-value="value"
                    label="Priorisierung"
                />
            </template>
            <template #actions>
                <v-btn color="danger" @click="form.reset()">{{
                    $t('Reset')
                }}</v-btn>
                <SaveButton
                    class="tw-mr-2"
                    :loading="form.processing"
                    :disabled="!form.isDirty"
                ></SaveButton>
            </template>
        </FormSection>
    </v-container>
</template>

<style scoped></style>
