<script setup>
import ActionSection from '@/Components/ActionSection.vue';
import { router, usePage } from '@inertiajs/vue3';
import {
    mdiDelete,
    mdiDownloadCircle,
    mdiFileExcel,
    mdiNoteEditOutline,
    mdiPlus,
} from '@mdi/js';
import colors from 'tailwindcss/colors';
import OutlinedSheet from '@/Components/OutlinedSheet.vue';
import { computed, inject, reactive } from 'vue';
import ParamInputs from '@/Pages/ExcelView/Partials/ParamInputs.vue';

const page = usePage();
const confirm = inject('confirm');
const download = (href) => {
    window.open(href, '_blank');
};
const deleteExport = async (id) => {
    if (
        await confirm.value(
            'Sind Sie sicher, dass Sie diesen Export löschen möchten?',
        )
    ) {
        router.delete(route('excel-views.destroy', id));
    }
};

const editExport = (id) => {
    router.visit(route('excel-views.edit', id));
};

const excelExports = computed(() => {
    if (page.props.all_exports.length > 0) {
        return page.props.all_exports;
    }

    return page.props.exports;
});

const params = reactive({
    item_number: null,
});
</script>

<template>
    <v-container>
        <ActionSection toolbar-density="compact" toolbar-color="info">
            <template #title>Daten Exporte</template>
            <template #description
                >In diesem Bereich finden Sie Ihre individuellen Daten-Exporte
                als Excel-Dateien bereit gestellt. Falls Sie spezielle
                Anforderungen oder Wünsche zu bestimmten Exporten unserer Daten
                haben, steht Ihnen unser Kundensupport gerne zur Verfügung.
            </template>
            <template v-if="page.props.auth.canEditExports" #toolbar>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn
                        :prepend-icon="mdiPlus"
                        color="black"
                        variant="elevated"
                        @click="router.visit(route('excel-views.create'))"
                        >Create Excel View</v-btn
                    >
                </v-toolbar-items>
            </template>
            <template #content>
                <div
                    v-if="excelExports.length === 0"
                    class="tw-flex tw-min-h-64 tw-w-full tw-items-center tw-justify-center tw-text-base tw-font-light tw-uppercase"
                >
                    keine Exporte vorhanden
                </div>
                <ul class="tw-list-none">
                    <li
                        v-for="excelExport in excelExports"
                        :key="excelExport.url"
                        class="tw-p-2"
                    >
                        <OutlinedSheet>
                            <div
                                class="tw-flex tw-h-10 tw-items-center tw-justify-between"
                            >
                                <div>
                                    <v-icon
                                        class="tw-mr-2"
                                        :icon="mdiFileExcel"
                                        :color="colors.green['800']"
                                    ></v-icon>
                                    <span class="tw-text-base tw-font-light">{{
                                        excelExport.filename
                                    }}</span>
                                </div>
                                <div>
                                    <v-btn
                                        v-if="page.props.auth.canEditExports"
                                        class="tw-mr-2"
                                        size="small"
                                        variant="outlined"
                                        color="danger"
                                        @click="deleteExport(excelExport.id)"
                                        ><v-icon :icon="mdiDelete"></v-icon
                                    ></v-btn>
                                    <v-btn
                                        v-if="page.props.auth.canEditExports"
                                        class="tw-mr-2"
                                        size="small"
                                        variant="outlined"
                                        color="info"
                                        @click="editExport(excelExport.id)"
                                        ><v-icon
                                            :icon="mdiNoteEditOutline"
                                        ></v-icon
                                    ></v-btn>
                                    <v-btn
                                        color="success"
                                        size="small"
                                        variant="outlined"
                                        :prepend-icon="mdiDownloadCircle"
                                        @click="
                                            download(
                                                route('excel-views.export', {
                                                    excel_view: excelExport.id,
                                                    ...params,
                                                }),
                                            )
                                        "
                                        >Download</v-btn
                                    >
                                </div>
                            </div>

                            <v-divider class="tw-mb-2"></v-divider>
                            <ParamInputs
                                v-model="params"
                                :params="excelExport.params"
                            ></ParamInputs>
                            <div
                                class="tw-text-sm tw-font-light tw-text-slate-600"
                            >
                                {{ excelExport.description }}
                            </div>
                        </OutlinedSheet>
                    </li>
                </ul>
            </template>
        </ActionSection>
    </v-container>
</template>

<style scoped></style>
