<script setup>
import { computed, ref, watch } from 'vue';
import {
    mdiAlert,
    mdiArrowLeft,
    mdiArrowRight,
    mdiFileDocumentAlertOutline,
    mdiNumeric,
} from '@mdi/js';
import { collect } from 'collect.js';
import ScanCheckmark from '@/Pages/Pick/Partials/ScanCheckmark.vue';

const props = defineProps({
    bin: {
        type: Object,
        required: true,
    },
    itemBarcode: {
        type: [String, null],
        default: null,
    },
    previousBin: {
        type: [Object, null],
        required: true,
    },
    nextBin: {
        type: [Object, null],
        required: true,
    },
    currentPackageIdentifier: {
        type: String,
        required: true,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    stock: {
        type: Object,
        required: true,
    },
    //Collection
    scans: {
        type: [Object, null],
        required: true,
    },
    rest: {
        type: Number,
        required: true,
    },
    barcodeScanned: {
        type: Boolean,
        required: true,
    },
    itemScanned: {
        type: Boolean,
        required: true,
    },
    binScanned: {
        type: Boolean,
        required: true,
    },
    errorMessage: {
        type: [String, null],
        default: null,
        required: false,
    },
    packagesById: {
        type: Object,
        required: true,
    },
    noScan: {
        type: Boolean,
        default: false,
    },
    meta: {
        type: [Object, null],
        default: null,
    },
});

const emit = defineEmits(['confirm', 'partialConfirm', 'open-ticket']);
const confirm = () => {
    emit('confirm', props.bin);
};

const partialConfirm = () => {
    emit('partialConfirm', props.bin);
};

const scanQuantity = computed(() => {
    return props.scans?.sum('quantity') ?? 0;
});

const scanned = computed(() => {
    return scanQuantity.value === props.bin.quantity;
});

const scansByPackage = computed(() => {
    return Object.entries(
        collect(props.scans)
            .groupBy('pick_package_id')
            .map((scans) => scans.sum('quantity'))
            .mapWithKeys((quantity, packageId) => [
                props.packagesById.get(packageId).identifier,
                quantity,
            ])
            .all(),
    );
});

const withoutScan = ref(false);

watch(
    () => props.bin.id,
    () => (withoutScan.value = false),
);
</script>

<template>
    <div>
        <v-alert
            v-if="errorMessage"
            class="tw-mb-2"
            :icon="mdiAlert"
            color="danger"
            variant="outlined"
        >
            {{ errorMessage }}
        </v-alert>
        <v-card
            :class="{
                'tw-border-2 tw-border-solid tw-border-red-500':
                    errorMessage !== null,
            }"
        >
            <v-toolbar :class="{ 'tw-bg-red-100': meta?.priority ?? 0 > 0 }">
                <v-toolbar-items class="tw-w-full">
                    <slot></slot>
                </v-toolbar-items>
            </v-toolbar>
            <div class="tw-flex tw-h-[64px] tw-w-full tw-bg-slate-300 tw-py-2">
                <div v-if="previousBin !== null">
                    <div>
                        <span class="tw-mr-1"
                            ><v-icon :icon="mdiArrowLeft"></v-icon> </span
                        ><span class="tw-font-bold">
                            {{ previousBin.bin_code }}</span
                        ><span class="tw-ml-4"
                            >{{ previousBin.quantity }}
                            {{ previousBin.unit }}</span
                        >
                    </div>
                    <div
                        class="tw-ml-6 tw-overflow-hidden tw-whitespace-nowrap"
                    >
                        <span>{{ previousBin.item.Description }}</span>
                        <span v-if="previousBin.item['Description 2'] !== ''">
                            ({{ previousBin.item['Description 2'] }})
                        </span>
                    </div>
                </div>
            </div>
            <v-divider></v-divider>
            <div
                class="tw-m-1 tw-p-4"
                :class="{
                    'tw-rounded-lg tw-border tw-border-solid  tw-border-success':
                        scanned,
                }"
            >
                <div>
                    <div class="tw-flex tw-items-center">
                        <span class="tw-mr-2 tw-text-2xl tw-font-bold">{{
                            bin.bin_code
                        }}</span>
                        <ScanCheckmark v-if="binScanned"></ScanCheckmark>
                    </div>
                    <div>
                        <span class="tw-text-gray-500">{{
                            bin.item.Description
                        }}</span>
                        <span
                            v-if="bin.item['Description 2'] !== ''"
                            class="tw-text-gray-500"
                            >({{ bin.item['Description 2'] }})</span
                        >
                    </div>
                    <div class="tw-flex tw-items-center tw-text-gray-500">
                        <div class="tw-mr-4">
                            EAN:
                            <span v-if="itemBarcode">{{ itemBarcode }}</span>
                            <span v-else>---</span>
                        </div>

                        <ScanCheckmark v-if="itemBarcode && itemScanned" />
                    </div>
                    <div
                        v-if="bin.batch !== '' && bin.batch !== null"
                        class="tw-text-gray-500"
                    >
                        Charge: {{ bin.batch }}
                    </div>
                    <div class="tw-text-4xl">
                        <span class="tw-font-extrabold tw-text-red-500">{{
                            bin.quantity
                        }}</span>
                        <span class="tw-ml-2 tw-text-2xl">{{ bin.unit }}</span>
                    </div>
                </div>
                <div class="tw-text-lg tw-font-bold tw-text-cyan-500">
                    <span v-if="!scanned"
                        >Ziel: {{ currentPackageIdentifier }}</span
                    >
                </div>
                <div
                    v-if="scans?.count() ?? 0 > 0"
                    class="tw-text-lg tw-font-bold tw-text-blue-500"
                >
                    <div><span> Gepickt auf: </span></div>
                    <div>
                        <ul>
                            <li
                                v-for="(scan, index) in scansByPackage"
                                :key="index"
                                class="tw-list-none"
                            >
                                <span
                                    >{{ scan[0] }} - {{ scan[1] }}
                                    {{ bin.unit }}</span
                                >
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <span
                        >Rest:
                        {{
                            stock[
                                bin.location_code +
                                    '_' +
                                    bin.bin_code +
                                    '_' +
                                    bin.item_number
                            ]['Qty_ (Base)']
                        }}</span
                    >
                </div>
                <div class="tw-mt-4 tw-flex tw-items-center">
                    <div v-if="!scanned" class="tw-flex tw-items-center">
                        <div>
                            <v-btn
                                class="tw-mr-2"
                                :disabled="
                                    (!barcodeScanned && !withoutScan) ||
                                    rest === 0
                                "
                                size="large"
                                color="primary"
                                @click="confirm"
                                >Fertig
                            </v-btn>
                            <v-btn
                                :disabled="
                                    (!barcodeScanned && !withoutScan) ||
                                    rest === 0
                                "
                                color="info"
                                :icon="mdiNumeric"
                                @click="partialConfirm"
                            ></v-btn>
                        </div>
                        <div
                            v-if="noScan || bin.ticket_id !== null"
                            class="tw-ml-2"
                        >
                            <v-checkbox
                                v-model="withoutScan"
                                color="primary"
                                density="comfortable"
                                hide-details
                                label="Ohne Scan"
                            ></v-checkbox>
                        </div>
                    </div>
                    <div v-if="!scanned">
                        <slot name="buttons"></slot>
                    </div>
                    <v-btn
                        v-else
                        size="large"
                        class="tw-mt-4"
                        variant="outlined"
                        color="primary"
                        >Gepickt
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        :icon="mdiFileDocumentAlertOutline"
                        color="danger"
                        :disabled="bin.ticket_id !== null"
                        @click="emit('open-ticket')"
                    ></v-btn>
                </div>
            </div>
            <v-divider></v-divider>
            <div class="tw-h-[64px] tw-bg-slate-300 tw-py-2">
                <div v-if="nextBin !== null">
                    <div>
                        <span class="tw-mr-1"
                            ><v-icon :icon="mdiArrowRight"></v-icon></span
                        ><span class="tw-font-bold">
                            {{ nextBin.bin_code }}</span
                        ><span class="tw-ml-4"
                            >{{ nextBin.quantity }} {{ nextBin.unit }}</span
                        >
                    </div>
                    <div
                        class="tw-ml-6 tw-overflow-hidden tw-whitespace-nowrap"
                    >
                        <span>{{ nextBin.item.Description }}</span>
                        <span v-if="nextBin.item['Description 2'] !== ''">
                            ({{ nextBin.item['Description 2'] }})
                        </span>
                    </div>
                </div>
            </div>
        </v-card>
    </div>
</template>
