<script setup>
import { router, usePage } from '@inertiajs/vue3';
import { computed, onMounted, onUnmounted, reactive, ref } from 'vue';
import TableCell from '@/Pages/ProductionDashboard/Partials/TableCell.vue';
import { useLayout } from 'vuetify';
import {
    useFullscreen,
    useIntervalFn,
    useScroll,
    useWindowSize,
} from '@vueuse/core';
import AppBarActionsTeleport from '@/Components/AppBarActionsTeleport.vue';
import { mdiClose, mdiFullscreen } from '@mdi/js';
import TableToggleHeader from '@/Pages/ProductionDashboard/Partials/TableToggleHeader.vue';
import OrderTable from '@/Pages/ProductionDashboard/Partials/OrderTable.vue';

const page = usePage();
const { mainRect } = useLayout();
const { height: windowHeight } = useWindowSize();
const table = ref(null);

const fullscreen = route().current('production-dashboard.fullscreen');
const { toggle: toggleFullscreen } = useFullscreen(table);

const filters = reactive({
    location: null,
    MMG: false,
    MGR: false,
    MOB: false,
    MPO: false,
    MPS: false,
    MAS: false,
    'MOF/MRP': false,
});
const rows = computed(() => {
    let data = page.props.data;

    Object.entries(filters).forEach(([key, value]) => {
        if (value === true) {
            data = data.filter((row) => row[key]?.order_count > 0);
            return;
        }

        if (value) {
            data = data.filter((row) => row[key] === value);
        }
    });

    return data;
});

const height = computed(() => {
    return windowHeight.value - mainRect.value.top - 10;
});

const showOrderModal = ref(false);
const ordersLoading = ref(false);
const openedRow = ref(null);
const openedStatus = ref(null);
const orders = ref([]);
const openOrderModal = async (row, status) => {
    showOrderModal.value = true;
    openedRow.value = row;
    openedStatus.value = status;
    ordersLoading.value = true;

    const response = await axios.get(route('api.dashboard.production.orders'), {
        params: {
            filter: {
                customer_name: row.customer,
                status: status,
            },
        },
    });
    orders.value = response.data;
    ordersLoading.value = false;
};

const tableWrapper = ref(null);
const scroll = useScroll(tableWrapper, {
    smooth: true,
});
let interval = null;
let scrollInterval = null;
onMounted(() => {
    interval = setInterval(() => {
        router.reload({
            only: ['data'],
        });
    }, 10000);

    if (fullscreen) {
        let direction = 'down';
        scrollInterval = useIntervalFn(() => {
            if (direction === 'down' && scroll.arrivedState.bottom) {
                direction = 'up';
                scrollInterval.pause();
                setTimeout(() => {
                    scrollInterval.resume();
                }, 5000);
            } else if (direction === 'up' && scroll.arrivedState.top) {
                direction = 'down';
                scrollInterval.pause();
                setTimeout(() => {
                    scrollInterval.resume();
                }, 5000);
            }

            switch (direction) {
                case 'down':
                    scroll.y.value += 1;
                    break;
                case 'up':
                    scroll.y.value -= 1;
                    break;
            }
        }, 50);
    }
});
onUnmounted(() => {
    clearInterval(interval);
    if (scrollInterval) clearInterval(scrollInterval);
});
</script>

<script>
import FullscreenLayout from '@/Layouts/FullscreenLayout.vue';

const fullscreenDefault = route().current('production-dashboard.fullscreen');

export default { layout: fullscreenDefault ? FullscreenLayout : undefined };
</script>

<template>
    <AppBarActionsTeleport v-if="!fullscreen">
        <v-btn
            color="info"
            :icon="mdiFullscreen"
            @click="toggleFullscreen"
        ></v-btn>
    </AppBarActionsTeleport>
    <v-dialog v-model="showOrderModal" :attach="table?.$el" @dblclick.stop>
        <v-card>
            <template #title>
                <div class="tw-flex tw-w-full">
                    <span>{{ openedRow.customer }} - {{ openedStatus }}</span>
                    <v-spacer></v-spacer>
                    <v-icon
                        :icon="mdiClose"
                        @click="showOrderModal = false"
                    ></v-icon>
                </div>
            </template>
            <div class="tw-p-2">
                <div
                    v-if="ordersLoading"
                    class="tw-flex tw-h-48 tw-items-center tw-justify-center"
                >
                    <v-progress-circular
                        color="primary"
                        size="large"
                        indeterminate
                    ></v-progress-circular>
                </div>
                <div v-else>
                    <OrderTable :row-data="orders"></OrderTable>
                    <!--                <v-list density="comfortable">
                            <v-list-item
                                v-for="order in orders"
                                :key="order.order_number"
                                :base-color="order.highlighted ? 'danger' : null"
                                @click="
                                    router.visit(
                                        route('order.index', {
                                            open: order.order_number,
                                        }),
                                    )
                                "
                            >
                                <div class="tw-flex tw-w-full">
                                    <div
                                        class="tw-w-2"
                                        :class="{
                                            'tw-bg-green-600': !order.highlighted,
                                            'tw-bg-red-500': order.highlighted,
                                        }"
                                    ></div>
                                    <div class="tw-pl-4">
                                        {{ order.order_number }}
                                        <span v-if="order.delivery_note_number"
                                            >/ {{ order.delivery_note_number }}</span
                                        >
                                        - GLD:
                                        {{ formatDate(order.requested_delivery_date) }},
                                        <span v-if="order.pick_list_created_at"
                                            >PL:
                                            {{
                                                formatDate(order.pick_list_created_at)
                                            }}</span
                                        >
                                        <br />
                                        Servicelevel:
                                        {{ order.servicelevel }} - Zusteller:
                                        {{ order.shipping_agent }} /
                                        {{ order.shipping_agent_service }}
                                    </div>
                                </div>
                            </v-list-item>
                        </v-list>-->
                </div>
            </div>
        </v-card>
    </v-dialog>
    <v-table
        ref="table"
        fixed-header
        :height
        class="tw-w-full tw-bg-surface"
        @dblclick="toggleFullscreen"
    >
        <template #wrapper>
            <div
                ref="tableWrapper"
                class="v-table__wrapper"
                :style="{ height: height + 'px' }"
            >
                <table>
                    <thead>
                        <tr>
                            <th
                                class="tw-min-w-36 tw-border-b tw-border-l-0 tw-border-r-2 tw-border-t-0 tw-border-solid tw-border-r-green-700 tw-border-opacity-35"
                            >
                                Kunde
                            </th>
                            <th
                                class="tw-min-w-36 tw-border-b tw-border-l-0 tw-border-r-2 tw-border-t-0 tw-border-solid tw-border-r-green-700 tw-border-opacity-35 tw-px-0"
                            >
                                <v-select
                                    v-model="filters.location"
                                    :items="['LAGER IZ', 'POT']"
                                    bg-color="surface"
                                    clearable
                                    class="tw-h-full tw-w-full"
                                    hide-details
                                    label="Lager"
                                    color="primary"
                                    :menu-props="{
                                        attach: table?.$el,
                                    }"
                                ></v-select>
                            </th>

                            <TableToggleHeader v-model="filters.MMG"
                                >MMG
                            </TableToggleHeader>
                            <TableToggleHeader v-model="filters.MGR"
                                >MGR
                            </TableToggleHeader>
                            <TableToggleHeader v-model="filters.MOB"
                                >MOB
                            </TableToggleHeader>
                            <TableToggleHeader v-model="filters.MPO"
                                >MPO
                            </TableToggleHeader>
                            <TableToggleHeader v-model="filters.MPS"
                                >MPS
                            </TableToggleHeader>
                            <TableToggleHeader v-model="filters.MAS"
                                >MAS
                            </TableToggleHeader>
                            <TableToggleHeader v-model="filters['MOF/MRP']"
                                >MOF/MRP
                            </TableToggleHeader>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="row in rows"
                            :key="row.customer_name"
                            class="odd:tw-bg-gray-100"
                        >
                            <td
                                class="tw-min-w-36 tw-border-b tw-border-l-0 tw-border-r-2 tw-border-t-0 tw-border-solid tw-border-r-green-700 tw-border-opacity-35 tw-text-lg"
                            >
                                {{ row.customer }}<br />
                            </td>
                            <td
                                class="tw-min-w-36 tw-border-b tw-border-l-0 tw-border-r-2 tw-border-t-0 tw-border-solid tw-border-r-green-700 tw-border-opacity-35 tw-text-lg"
                            >
                                {{ row.location }}<br />
                            </td>

                            <TableCell
                                :status="row.MMG"
                                @open="openOrderModal(row, 'MMG')"
                            ></TableCell>
                            <TableCell
                                :status="row.MGR"
                                @open="openOrderModal(row, 'MGR')"
                            ></TableCell>
                            <TableCell
                                :status="row.MOB"
                                @open="openOrderModal(row, 'MOB')"
                            ></TableCell>
                            <TableCell
                                :status="row.MPO"
                                @open="openOrderModal(row, 'MPO')"
                            ></TableCell>
                            <TableCell
                                :status="row.MPS"
                                @open="openOrderModal(row, 'MPS')"
                            ></TableCell>
                            <TableCell
                                :status="row.MAS"
                                @open="openOrderModal(row, 'MAS')"
                            ></TableCell>
                            <TableCell
                                :status="row['MOF/MRP']"
                                @open="openOrderModal(row, 'MOF,MRP')"
                            ></TableCell>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
    </v-table>
</template>

<style scoped></style>
