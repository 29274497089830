<script setup>
import { mdiFloppy, mdiTrashCan } from '@mdi/js';
import { useLocalStorage } from '@vueuse/core';
import { nextTick, ref, watch } from 'vue';

const props = defineProps({
    gridApi: {
        type: [Object, null],
        required: true,
    },
});

const filter = defineModel('filter', {
    type: Object,
    required: true,
});

const layouts = useLocalStorage(
    'reporting-layouts',
    {
        defaultLayout: null,
        layouts: [],
    },
    {
        mergeDefaults: true,
    },
);

const currentLayout = ref(
    layouts.value.defaultLayout === null
        ? null
        : layouts.value.layouts.find(
              (layout) => layout.name === layouts.value.defaultLayout,
          ),
);

if (currentLayout.value !== null) {
    filter.value.type = currentLayout.value.filterUI.type;
    filter.value.startDate = currentLayout.value.filterUI.startDate;
    filter.value.endDate = currentLayout.value.filterUI.endDate;
}

const search = ref(currentLayout.value ? currentLayout.value.name : '');
const showSnackbar = ref(false);
const saveLayout = () => {
    if (search.value === '') {
        return;
    }

    let layout = layouts.value.layouts.find(
        (layout) => layout.name === search.value,
    );

    if (layout !== undefined) {
        layout.state = props.gridApi.getColumnState();
        layout.filter = props.gridApi.getFilterModel();
        layout.filterUI = filter.value;
        currentLayout.value = layout;
        showSnackbar.value = true;
        return;
    }

    layout = {
        name: search.value,
        state: props.gridApi.getColumnState(),
        filter: props.gridApi.getFilterModel(),
        filterUI: filter.value,
    };
    layouts.value.layouts.push(layout);

    currentLayout.value = layout;
    showSnackbar.value = true;
};

watch(
    () => props.gridApi,
    (api) => {
        if (api === null || !(currentLayout.value instanceof Object)) {
            return;
        }

        api.applyColumnState({
            state: currentLayout.value.state,
        });
        api.setFilterModel(currentLayout.value.filter);
    },
);

watch(currentLayout, (newLayout) => {
    if (newLayout === null) {
        nextTick(() => {
            layouts.value.defaultLayout = null;
        });
        return;
    }

    if (!(newLayout instanceof Object)) {
        return;
    }

    props.gridApi.applyColumnState({
        state: newLayout.state,
    });
    props.gridApi.setFilterModel(newLayout.filter);
    filter.value.filterUI = newLayout.filterUI;
    filter.value.startDate = newLayout.filterUI.startDate;
    filter.value.endDate = newLayout.filterUI.endDate;

    nextTick(() => {
        layouts.value.defaultLayout = newLayout.name;
    });
});

const deleteItem = (item) => {
    layouts.value.layouts.splice(
        layouts.value.layouts.findIndex(
            (layout) => layout.name === item.raw.name,
        ),
        1,
    );
    if (currentLayout.value?.name === item.raw.name) {
        layouts.value.defaultLayout = null;
        currentLayout.value = null;
        search.value = '';
    }
};
</script>

<template>
    <v-snackbar
        v-model="showSnackbar"
        :timeout="2000"
        color="primary"
        variant="tonal"
    >
        Layout <span class="tw-font-bold">{{ search }}</span> gespeichert
    </v-snackbar>
    <v-combobox
        v-model:search="search"
        v-model="currentLayout"
        color="primary"
        class="tw-w-80 tw-grow-0 tw-pr-2"
        variant="filled"
        density="compact"
        :items="layouts.layouts"
        item-title="name"
        return-object
        label="Layout"
        single-line
        hide-details
    >
        <template #append>
            <v-icon
                :disabled="search === ''"
                class="tw-opacity-80"
                :icon="mdiFloppy"
                color="secondary"
                @click="saveLayout"
            ></v-icon>
        </template>
        <template #item="{ props: itemProps, item }">
            <v-list-item v-bind="itemProps" :title="null">
                {{ item.title }}
                <template #append>
                    <v-icon
                        :icon="mdiTrashCan"
                        @click="deleteItem(item)"
                    ></v-icon>
                </template>
            </v-list-item>
        </template>
    </v-combobox>
</template>

<style scoped>
:deep(.v-field__input) {
    padding-top: 0;
    padding-bottom: 0;
}
</style>
