import { reactive, ref } from 'vue';

export const timeData = reactive({
    data: null,
    types: null,
    customer: null,
});
export const errors = ref({});

export async function refreshClockData() {
    const response = await axios.get(route('api.clock.index'));
    timeData.data = response.data.data;
    timeData.types = response.data.types;
    timeData.customer = response.data.customer;
}

export const submittingData = ref(false);

/**
 * @typedef {Object} FormData
 * @property {string|null} activity
 * @property {string|null} ean
 * @property {string|null} customer_number
 * @property {string|null} additional_info
 * @property {Number|null} special_order_id
 * @property {string|null} san
 * @property {boolean|null} force
 */
/**
 * @param {FormData} formData
 */
export async function startClock(formData) {
    submittingData.value = true;
    try {
        const response = await axios.post(route('api.clock.start'), formData);
        timeData.data = response.data.data;
        timeData.customer = response.data.customer;
        errors.value = {};
    } catch (e) {
        if (e.response?.status === 422) {
            errors.value = e.response.data.errors;
        }
    }
    submittingData.value = false;
}
export async function stopClock(timeId = null) {
    submittingData.value = true;
    let response = null;
    if (timeId !== null) {
        response = await axios.put(route('api.clock.stop', { time: timeId }));
    } else {
        response = await axios.put(route('api.clock.stopAny'));
    }
    timeData.data = null;
    submittingData.value = false;
    return response;
}
