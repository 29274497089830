<script setup>
import { ref, watch } from 'vue';

const toggle = defineModel({
    type: Boolean,
    required: true,
});

const value = ref(toggle.value);

watch(value, (newValue) => {
    toggle.value = newValue === 0;
});
</script>

<template>
    <th
        class="tw-min-w-36 tw-border-b tw-border-l-0 tw-border-r-2 tw-border-t-0 tw-border-solid tw-border-r-green-700 tw-border-opacity-35 tw-px-0 tw-text-center"
    >
        <v-btn-toggle
            v-model="value"
            color="primary"
            :rounded="false"
            class="tw-mb-[2px] tw-h-full tw-w-full"
        >
            <v-btn
                base-color="surface"
                class="tw-h-full tw-w-full"
                :rounded="false"
                ><slot></slot
            ></v-btn>
        </v-btn-toggle>
    </th>
</template>

<style scoped></style>
