<script setup>
import { computed, reactive, ref } from 'vue';
import { watchIgnorable } from '@vueuse/core';

const emit = defineEmits(['apply']);

const getDates = () => {
    const startDate = moment('2024-01-01');
    const endDate = moment();

    const dates = [];
    while (startDate.isSameOrBefore(endDate, 'days')) {
        dates.push(startDate.format('MM/YY'));
        startDate.add(1, 'months');
    }
    return dates;
};

const dates = getDates();
const filter = defineModel({
    type: [Object, null],
    required: true,
});

const startDate = ref(filter.value.startDate);
const endDate = ref(filter.value.endDate);

const originalDates = reactive({
    startDate: filter.value.startDate,
    endDate: filter.value.endDate,
});

const dirty = computed(() => {
    return (
        startDate.value !== originalDates.startDate ||
        endDate.value !== originalDates.endDate
    );
});

const { ignoreUpdates } = watchIgnorable(
    [() => filter.value.startDate, () => filter.value.endDate],
    () => {
        startDate.value = filter.value.startDate;
        endDate.value = filter.value.endDate;
        originalDates.startDate = filter.value.startDate;
        originalDates.endDate = filter.value.endDate;
    },
);
const filterDates = () => {
    originalDates.startDate = startDate.value;
    originalDates.endDate = endDate.value;
    ignoreUpdates(() => {
        filter.value.startDate = startDate.value;
        filter.value.endDate = endDate.value;
    });
    emit('apply');
};

const { ignoreUpdates: ignoreTypeUpdate } = watchIgnorable(
    () => filter.value.type,
    (value) => {
        if (value === undefined) {
            ignoreTypeUpdate(() => {
                filter.value.type = null;
            });
        }

        if (value !== 'date_range') {
            emit('apply');
        }

        if (
            value === 'date_range' &&
            startDate.value !== null &&
            endDate.value !== null &&
            !dirty.value
        ) {
            emit('apply');
        }
    },
);
</script>

<template>
    <div class="tw-flex tw-grow tw-items-center">
        <v-btn-toggle
            v-model="filter.type"
            density="compact"
            color="primary"
            class="tw-w-[450px]"
        >
            <v-fade-transition group leave-absolute hide-on-leave>
                <v-btn
                    v-show="filter.type !== 'date_range'"
                    key="this_month"
                    value="this_month"
                    >Dieser Monat</v-btn
                >
                <v-btn
                    v-show="filter.type !== 'date_range'"
                    key="last_month"
                    value="last_month"
                    >Letzter Monat</v-btn
                >
                <v-slide-x-transition key="date_range">
                    <v-btn value="date_range">Zeitraum</v-btn>
                </v-slide-x-transition>
            </v-fade-transition>

            <div
                v-if="filter.type === 'date_range'"
                class="tw-flex tw-items-center"
            >
                <v-select
                    v-model="startDate"
                    class="tw-m-2 tw-min-w-24"
                    density="compact"
                    :items="dates"
                    label="Von"
                    hide-details
                    color="primary"
                ></v-select>
                <v-select
                    v-model="endDate"
                    class="tw-min-w-24"
                    density="compact"
                    :items="dates"
                    label="Bis"
                    hide-details
                    color="primary"
                ></v-select>
                <v-btn
                    rounded
                    value="date_range"
                    class="tw-ml-2 tw-mt-1 !tw-h-full"
                    variant="elevated"
                    color="primary"
                    :disabled="!dirty"
                    @click="filterDates"
                    >Filtern</v-btn
                >
            </div>
        </v-btn-toggle>
    </div>
</template>

<style scoped></style>
