<script setup>
import { useForm, usePage } from '@inertiajs/vue3';
import FormSection from '@/Components/FormSection.vue';
import TextField from '@/Components/TextField.vue';
import SelectField from '@/Components/SelectField.vue';
import TextareaField from '@/Pages/Order/TextareaField.vue';
import SaveButton from '@/Components/SaveButton.vue';
import ExcelButton from '@/Components/ExcelButton.vue';
import UserCombobox from '@/Components/UserCombobox.vue';

const page = usePage();

const form = useForm({ ...page.props.excel_view });

const submit = () => {
    if (form.id) {
        form.put(route('excel-views.update', form.id));
    } else {
        form.post(route('excel-views.store'));
    }
};
</script>

<template>
    <v-container>
        <FormSection @submitted="submit">
            <template #title>Excel Ansicht Erstellen</template>
            <template #description> Erstellen Sie eine Excel Ansicht.</template>
            <template #form>
                <TextField
                    v-model="form.name"
                    label="Name"
                    :error-messages="form.errors.name"
                ></TextField>
                <TextField
                    v-model="form.description"
                    label="Beschreibung"
                    :error-messages="form.errors.description"
                ></TextField>
                <SelectField
                    v-model="form.connection"
                    label="Connection"
                    :items="['statistik_sqlsrv', 'nav_sqlsrv', 'mysql']"
                    :error-messages="form.errors.connection"
                ></SelectField>
                <TextareaField
                    v-model="form.query"
                    label="Query"
                    hint="Query soll ein SELECT Statement sein. Es können die namen der connections (eg. :nav_sqlrv) verwendet werden um dynamisch den Datenbanknamen zu inkludieren"
                    :error-messages="form.errors.query"
                ></TextareaField>
                <TextField
                    v-model="form.params"
                    label="Parameter"
                    hint="Parameter müssen im Query als ? existieren und durch Semikolon getrennt sein."
                    :error-messages="form.errors.params"
                ></TextField>
                <UserCombobox
                    v-model="form.user_ids"
                    :selected="page.props.users"
                    :error-messages="form.errors.user_ids"
                ></UserCombobox>
                <v-divider class="tw-mb-4"></v-divider>
                <div class="tw-flex tw-w-full">
                    <ExcelButton
                        v-if="form.id"
                        :href="route('excel-views.export', form.id)"
                        >Exportieren</ExcelButton
                    >
                    <v-spacer></v-spacer>
                    <SaveButton
                        :disabled="!form.isDirty"
                        :loading="form.processing"
                        class="tw-mr-2"
                    ></SaveButton>
                    <v-btn :disabled="!form.isDirty" color="danger">{{
                        $t('Reset')
                    }}</v-btn>
                </div>
            </template>
        </FormSection>
    </v-container>
</template>

<style scoped></style>
