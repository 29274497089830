<script setup>
import { mdiClose } from '@mdi/js';
import DialogModal from '@/Components/DialogModal.vue';
import { computed, ref, watch } from 'vue';
import TextField from '@/Components/TextField.vue';
import SelectField from '@/Components/SelectField.vue';
import TextareaField from '@/Pages/Order/TextareaField.vue';
import { useApiForm } from '@/lib/composables/api-form.js';
import SaveButton from '@/Components/SaveButton.vue';

const show = defineModel({
    type: Boolean,
    default: true,
});
const props = defineProps({
    ean: {
        type: String,
        required: true,
    },
    bin: {
        type: Object,
        required: true,
    },
    stock: {
        type: Object,
        required: true,
    },
    rest: {
        type: Number,
        required: true,
    },
    customerNumber: {
        type: String,
        required: true,
    },
    ticketTypeId: {
        type: Number,
        required: true,
    },
    ticketSubtypeId: {
        type: Number,
        required: true,
    },
});
const emit = defineEmits(['created']);

const localStock = computed(() =>
    Number(
        props.stock[
            props.bin.location_code +
                '_' +
                props.bin.bin_code +
                '_' +
                props.bin.item_number
        ]['Qty_ (Base)'],
    ),
);

const itemBins = computed(() => {
    return Object.values(props.stock).filter(
        (stock) =>
            stock['Item No_'] === props.bin.item_number &&
            stock['Bin Code'] !== props.bin.bin_code &&
            stock['Qty_ (Base)'] >= props.rest &&
            (!props.bin.batch || stock['Lot No_'] === props.bin.batch),
    );
});

const form = useApiForm({
    pick_list_line_id: props.bin.id,
    customer_number: props.customerNumber,
    delivery_note_number: null,
    responsible: null,
    ticket_type_id: props.ticketTypeId,
    content_lines: [
        {
            subtype_id: props.ticketSubtypeId,
            attachments: [],
            batch: null,
            bin_code: null,
            item_number_1: null,
            location_code: props.bin.location_code,
            note: null,
            quantity: 0,
            text: null,
        },
    ],
});

const submit = async () => {
    const response = await form.submit(route('api.ticket.store'), 'post');
    emit('created', response.data.id);
};
const getItemTitle = (item) =>
    item['Bin Code'] +
    ' | ' +
    item['Qty_ (Base)'] +
    (props.bin.batch ? ' | ' + item['Lot No_'] : '');
const realQuantity = ref(0);
const selectedBin = ref(null);
const note = ref(null);

watch(
    () => props.bin.id,
    () => {
        form.defaults({
            ...form.data(),
            content_lines: [
                {
                    ...form.data().content_lines[0],
                    bin_code: props.bin.bin_code,
                    item_number_1: props.bin.item_number,
                    batch: props.bin.batch,
                    note: `Es liegen aber ${realQuantity.value} ${props.bin.item['Base Unit of Measure']} auf dem Lagerplatz ${props.bin.bin_code}.`,
                    quantity: localStock.value,
                },
            ],
        });
        form.reset();
    },
    { immediate: true, deep: true },
);

watch([realQuantity, note, selectedBin], () => {
    let noteText = `PL: ${props.ean}\n\nEs liegen aber ${realQuantity.value} ${props.bin.item['Base Unit of Measure']} auf dem Lagerplatz ${props.bin.bin_code}.`;

    if (selectedBin.value) {
        noteText += `\n\nDer Artikel wurde vom Lagerplatz ${selectedBin.value} genommen.`;
    }

    if (note.value) {
        noteText += `\n\n${note.value}`;
    }

    form.content_lines[0].note = noteText;
});
</script>

<template>
    <DialogModal :show @close="show = false">
        <template #title>
            <div class="tw-flex tw-w-full tw-items-center">
                Falscher Lagerstand beim Picken entdeckt.
                <v-spacer></v-spacer>
                <v-btn
                    variant="flat"
                    :icon="mdiClose"
                    @click="show = false"
                ></v-btn>
            </div>
        </template>
        <template #content>
            <p>
                Es sollten <b>{{ localStock }}</b> Stück auf dem Lagerplatz
                <b>{{ bin.bin_code }}</b> sein.
            </p>
            <div class="tw-flex tw-items-center">
                <div class="tw-mr-4">Es sind aber</div>
                <div>
                    <TextField
                        v-model="realQuantity"
                        class="!tw-mb-2 tw-w-44 tw-text-right"
                        density="compact"
                        hide-details="auto"
                        variant="underlined"
                        type="number"
                        required
                    ></TextField>
                </div>
            </div>
            <div class="tw-mb-4 tw-flex tw-items-center">
                <div class="tw-mr-4">Der Artikel wurde vom Lagerplatz</div>
                <div class="tw-mr-4">
                    <SelectField
                        v-model="selectedBin"
                        :disabled="itemBins.length === 0"
                        clearable
                        :items="itemBins"
                        class="!tw-mb-0 tw-w-44"
                        density="compact"
                        hide-details="auto"
                        variant="underlined"
                        :item-title="getItemTitle"
                        item-value="Bin Code"
                    ></SelectField>
                </div>
                genommen.
            </div>
            <TextareaField v-model="note" label="Bemerkung"></TextareaField>
        </template>
        <template #footer>
            <v-spacer></v-spacer>
            <SaveButton
                :loading="form.processing"
                :disabled="!form.isDirty"
                type="button"
                @click="submit"
            ></SaveButton>
        </template>
    </DialogModal>
</template>

<style scoped></style>
